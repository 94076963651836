import React, { useState } from "react";
import { Box } from "@mui/material";
import CardAset from "../../components/CardAset/CardAset";

export default function HomePage () {
    const [open] = useState(true);
    return(
        <Box className="w-full h-full pt-8">
            <Box className={`${ open ? 'pt-[2rem] ' : 'justify-center items-center' }`}>
                <CardAset />
            </Box>
        </Box>
    );
}