import React from "react";
import CardBarangMasuk from "../../components/CardBarangMasuk";

export default function BarangMasukPage () {
    return(
        <div className="max-h-full">
            <div className="relative top-[1rem]">
              <CardBarangMasuk/>
            </div>
        </div>
    );
}