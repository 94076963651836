import React from "react";
import CardBarangKeluar from "../../components/CardBarangKeluar";

export default function BarangMasukPage () {
    return(
        <div className="max-h-full">
            <div className="relative top-[1 rem]">
              <CardBarangKeluar/>
            </div>
        </div>
    );
}