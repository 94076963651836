import React, { useState, useEffect } from 'react';
import { Box } from "@mui/material";
import { useNavigate, Link } from 'react-router-dom';
import SucofindoLogo from '../../../assets/logo-sucofindo.png';
import { BsArrowLeftCircleFill } from 'react-icons/bs';
import { FaFolder, FaHome } from 'react-icons/fa';
import { RiFolderDownloadFill, RiFolderUploadFill, RiFolderHistoryFill } from 'react-icons/ri';
import axios from 'axios';
 
export default function Sidebar() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios.get('https://sima-rest-api.vercel.app/api/v1/user/profile', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
    .then(response => {
      const role = response.data.role;
      setRole(role);
    })
    .catch(error => {
      console.error('Error fetching user profile:', error);
    })
    .finally (() => {
      setLoading(false);
    });
  }
,[])
 
  const getMenuItems = () => {
    const userRole=`${role}`;
    const menuItemsByRole = {
      admin: [
        { icon: <FaHome 
          className={`${open ? 'cursor-pointer' : 'w-[4.72rem]'}`}
          color='white' size={`${ open ? 20 : 24 }`} 
        />, 
        to: '/Home', label: 'Home'
        },
        {   icon: <RiFolderDownloadFill
          className={`${open ? 'cursor-pointer' : 'w-[4.72rem]'}`}
          color='white' size={`${ open ? 20 : 26 }`} 
          />, 
          to: '/Barang-Keluar', label: 'Persetujuan Peminjaman'
        },
        {   icon: <RiFolderUploadFill
              className={`${open ? 'cursor-pointer' : 'w-[4.72rem] relative bottom-3'}`}
              color='white' size={`${ open ? 20 : 26 }`} 
            />, 
            to: '/Barang-Masuk', label: 'Persetujuan Pengembalian'
        },
        {   icon: <RiFolderHistoryFill
                className={`${open ? 'cursor-pointer' : 'w-[4.72rem] relative bottom-2'}`}
                color='white' size={`${ open ? 20 : 26 }`} 
            />, 
            to: '/Riwayat', label: 'Riwayat'
        },
      ],
      superAdmin: [
        { icon: <FaHome 
          className={`${open ? 'cursor-pointer' : 'cursor-pointer'}`}
          color='white' size={`${ open ? 20 : 24 }`} 
        />, 
        to: '/Home', label: 'Home'
        },
        {   icon: <FaFolder 
                    className={`${open ? 'cursor-pointer' : 'w-[4.72rem] relative bottom-2'}`}
                    color='white' size={`${ open ? 20 : 24 }`} 
            />, 
            to: '/Total-Aset', label: 'Total Aset Tersedia'
        },
        {   icon: <RiFolderDownloadFill
          className={`${open ? 'cursor-pointer' : 'w-[4.72rem] relative bottom-5'}`}
          color='white' size={`${ open ? 20 : 26 }`} 
          />, 
          to: '/Barang-Keluar', label: 'Persetujuan Peminjaman'
        },
        {   icon: <RiFolderUploadFill
             className={`${open ? 'cursor-pointer' : 'w-[4.72rem] relative bottom-4'}`}
              color='white' size={`${ open ? 20 : 26 }`} 
            />, 
            to: '/Barang-Masuk', label: 'Persetujuan Pengembalian'
        },
        {   icon: <RiFolderHistoryFill
                className={`${open ? 'cursor-pointer' : 'w-[4.72rem]'}`}
                color='white' size={`${ open ? 20 : 26 }`} 
            />, 
            to: '/Riwayat', label: 'Riwayat'
        },
      ],
    };
 
    return menuItemsByRole[userRole] || [];
  };
  const handleSidebarToggle = () => {
    setOpen(!open);
  };
 
  const handleButtonClick = (to) => {
    handleSidebarToggle();
    navigate(to);
  };
 
  const menuItems = getMenuItems();
  return (
    <aside className='flex z-10'>
      <Box className={`${open ? "w-[16.8rem]" : "w-[8rem]"} duration-300 bg-main-color border-white`}>
            <Box className={`${open ? "w-[16rem]" : "w-[8rem]"} fixed`}>
            <Box className='flex'>
              <Box className='mt-[1.5rem] ml-[1.5rem]'>
                <img
                  alt='sucofindo-logo'
                  className='w-[7.813rem] h-[5.375rem]'
                  src={SucofindoLogo}
                />
              </Box>
              <Box className='cursor-pointer mt-[4rem] ml-[4.5rem] mr-[1.5rem]'>
                <button>
                  <BsArrowLeftCircleFill
                    color='white'
                    className={`${open ? "rotate-0" : "rotate-180"} ${open ? "" : "relative"} ${open ? "" : "right-12"}`}
                    onClick={() => setOpen(!open)}
                    size={`${open ? 24 : 24}`}
                  />
                </button>
              </Box>
            </Box>
            <Box
              className={
                `${
                  open
                    ? 'mt-[1.5rem] ml-[1.5rem] w-[13rem] h-[0.031rem] bg-white'
                    : 'mt-[0.5rem] ml-[0.5rem] w-[7rem] h-[0.031rem] bg-white'
                }`
              }
            />
            {
              loading ? (
                <div className='text-white mt-4 ml-[1.6rem] text-14'>
                  Loading...
                </div>
              ) : (
            <ul className='ml-[1.5rem] mt-[2rem]'>
              {menuItems.map((item, i) => (
                <li key={i} className='flex'>
                <Link
                    className={`${open ? 'cursor-pointer' : 'flex justify-center items-center w-[12rem]'}`}
                    to={`${open ? item.to : item.to}`}
                  >
                    {item.icon}
                  </Link>
                  <Link
                      to={`${open ? item.to : item.to}`}
                      className={`ml-[0.75rem] text-[#E8E8E8] font-extralight hover:font-medium hover:text-white mb-[2rem] ${!open && 'opacity-0 translate-x-28 overflow-hidden'}`}
                      style={{
                        transitionDelay: `${i + 3}00ms`,
                      }}
                    >
                      {item.label}
                    </Link>
                </li>
              ))}
            </ul>
            )}
          </Box>
      </Box>
    </aside>
  );
}